/* Search Bar Styling */
.search-bar {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.search-bar input {
    padding: 10px;
    width: 100%;
    max-width: 300px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
    outline: none;
    transition: border-color 0.3s ease;
}

.search-bar input:focus {
    border-color: #007bff;
}

.search-bar button {
    padding: 10px 20px;
    font-size: 16px;
    margin-left: 10px;
    border-radius: 5px;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.search-bar button:hover {
    background-color: #0056b3;
}